import React from 'react';
import DesktopLayout from "../layouts/Desktop";

const page404 = () => {
  return (
    <DesktopLayout>
      <p>Please go back to home page!</p>
    </DesktopLayout>
  );
};

export default page404;
